import Head from "next/head";
import { useRouter } from "next/router";

const defaultMeta = {
  title: "UpSmith",
  siteName: "UpSmith",
  description:
    "UpSmith unlocks high wage, high growth, high purpose, and high dignity careers. Build your future, starting today.",
  url: "https://www.upsmith.com",
  type: "website",
  robots: "follow, index",
  image:
    // eslint-disable-next-line no-secrets/no-secrets
    "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fc5037b01f678beadd607683534ceae26.cdn.bubble.io%2Ff1649870578863x281833987957670560%2Fhero-image.jpeg?w=&h=&auto=compress&dpr=1&fit=max",
};

type SeoProps = {
  date?: string;
  templateTitle?: string;
} & Partial<typeof defaultMeta>;

export function Seo(props: SeoProps) {
  const router = useRouter();
  const meta = {
    ...defaultMeta,
    ...props,
  };
  meta.title = props.templateTitle
    ? `${props.templateTitle} | ${meta.siteName}`
    : meta.title;

  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="robots" content={meta.robots} />
      <meta content={meta.description} name="description" />
      <meta property="og:url" content={`${meta.url}${router.asPath}`} />
      <link rel="canonical" href={`${meta.url}${router.asPath}`} />
      {/* Open Graph */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:site_name" content={meta.siteName} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta name="image" property="og:image" content={meta.image} />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@upsmith" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      {/* <meta name='twitter:image' content={meta.image} /> */}
      {meta.date && (
        <>
          <meta property="article:published_time" content={meta.date} />
          <meta
            name="publish_date"
            property="og:publish_date"
            content={meta.date}
          />
        </>
      )}

      {/* Favicons */}
      {favicons.map((linkProps) => {
        return <link key={linkProps.href} {...linkProps} />;
      })}
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
    </Head>
  );
}

const favicons: Array<React.ComponentPropsWithoutRef<"link">> = [
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon/favicon-16x16.png",
  },
  { rel: "shortcut icon", href: "/favicon/favicon.ico" },
];
